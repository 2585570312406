import React from "react"
import { Container } from "reusecore/Layout"
import Heading from "reusecore/Heading"
import mainFeaturesImg from "../../assets/images/main-features/main-features.svg"

import MainFeaturesWrapper from "./main-features.style"

const MainFeatures = () => {


  return (
    <MainFeaturesWrapper id="mainFeatures">
      <Container>
        <div className="mainFeatures">
          <Heading>
            What we offer
          </Heading>
          <div className="offer-wrap">
            <ul>
              <li>AI matchmaking algorithm optimizes recruitment process. </li>
              <li>Freelancers’ competencies are minted on the blockchain and thus represent an instantly verifiable skillset.  </li>
              <li>Payments are secured with smart contracts that release funds only when the service is provided. </li>
              <li>A neutral auditors who are themselves part of an ecta ecosystem and thus incentivized to be just in their rulings, can be invited to resolve and mediate any disputes between the parties.</li>
            </ul>
          </div>
          <div className="mainFeatures__img">
            <img src={mainFeaturesImg} alt="Main features" />
          </div>
        </div>
      </Container>
    </MainFeaturesWrapper>
  )
}

export default MainFeatures

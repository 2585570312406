import React from "react";
import { SlickSlider, SliderItem } from "reusecore/SlickSlider";
import { Container } from "reusecore/Layout"
import { SectionTitle, SectionBackground } from "reusecore/SectionTitle"
import Heading from "reusecore/Heading"

import FramImg from "../../assets/images/supported-by/fram.png";
import KazojaImg from "../../assets/images/supported-by/kazoja.png";
import MinicomImg from "../../assets/images/supported-by/minicom.png";
import ProbitImg from "../../assets/images/supported-by/probit.png";
import QuamaImg from "../../assets/images/supported-by/quama.png";
import SimoraImg from "../../assets/images/supported-by/simora.png";
import StrokasiImg from "../../assets/images/supported-by/strokasi.png";
import EwasoftImg from "../../assets/images/supported-by/ewasoft.png";

import SupportedBySliderWrapper from "./SupportedBySlider.style";

const componentData = [
  FramImg,
  KazojaImg,
  MinicomImg,
  ProbitImg,
  QuamaImg,
  SimoraImg,
  StrokasiImg,
  EwasoftImg,
]


const SupportedBySlider = () => {
  var settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  };
  return (
    <SupportedBySliderWrapper>
      <Container>
        <SectionTitle>
          <SectionBackground>
            <Heading>
              Supported by
            </Heading>
          </SectionBackground>
        </SectionTitle>
        <SlickSlider {...settings}>
          {
            componentData.map((item, key) => (
              <SliderItem key={key}>
                <img src={item} alt="" />
              </SliderItem>
            ))
          }
        </SlickSlider>
      </Container>
    </SupportedBySliderWrapper>
  );
};

export default SupportedBySlider;

import React from "react"
import Data from "./utility/data.js";

import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import { SectionTitle, SectionBackground } from "reusecore/SectionTitle"

import TokenWrapper from "./token.style"

const Token = () => {
  return (
    <TokenWrapper id="token">
      <Container>
        <Row>
          <Col className="lg-6 md-6 sm-12">

            <div className="roadmap__container">
              {
                Data.map((year, key) => (
                  <div key={key}>
                    {/* <h2 className="roadmap__panel-title">{year.year}</h2> */}
                    <Row>
                      {
                        year.quartals.map((quartal, key) => (
                          <Col key={key} className="col-12 lg-6 roadmap__quartal">
                            <h3 className="roadmap__quartal--name"><span className="roadmap__quartal--circle"></span><span>{quartal.name}</span> {year.year}</h3>
                            <ul className="roadmap__quartal--list">
                              {
                                quartal.list.map((item, x) => (
                                  <li key={x}>
                                    <span className="roadmap__quartal--item">
                                      <span className="roadmap__quartal--itemName">
                                        {item.name}
                                      </span>
                                      {
                                        item.type &&
                                        <span className={`roadmap__quartal--itemType ${item.type === 'Project' ? '-project' : ''} ${item.type === 'Product' ? '-product' : ''} ${item.type === 'Marketing' ? '-marketing' : ''}`}>{item.type}</span>
                                      }
                                    </span>
                                    {
                                      item.innerList &&
                                      <ul className="roadmap__quartal--innerList">
                                        {
                                          item.innerList.map((item, key) => (
                                            <li>{item}</li>
                                          ))
                                        }
                                      </ul>
                                    }
                                  </li>

                                ))
                              }
                            </ul>
                          </Col>
                        ))
                      }
                    </Row>
                  </div>
                ))
              }
            </div>


          </Col>

          <Col className="lg-6 md-6 sm-12">
            <Box className="about-right-content">
              <SectionTitle>
                <SectionBackground>
                  <Heading as="h1">
                    WNS Token
                  </Heading>
                </SectionBackground>

                <Text>
                  Tokenomics of the platform will include the utility token which will also be used as a governance token in the future, allowing the community to participate in the future developments and roadmap.
                  <br /><br />
                  Airdrop rewards will be distributed to beta users.
                  {/* <br /><br />
                  Whitepaper coming soon. */}
                  <br /><br />
                  Subscribe to stay updated on the latest developments.
                </Text>
              </SectionTitle>

              <Box className="about-list">
                <Text as="span"><a href="https://worknskill.com/brochure.pdf" target="_blank" rel="noreferrer">Brochure</a></Text>
                {/* <Text as="span">Whitepaper</Text>
                <Text as="span">2-Pager</Text> */}
              </Box>

            </Box>
          </Col>
        </Row>
      </Container>

      {/* <Container>
        <Box>
          <SectionTitle className="banana">
            <SectionBackground >
              <Heading>
                WNS Token
              </Heading>
            </SectionBackground>
            <Text>
              Tokenomics of the platform will include the utility token which will also be used as a governance token in the future, allowing the community to participate in the future developments and roadmap.
            </Text>
          </SectionTitle>
          <div className="roadmap__container">
            {
              Data.map((year, key) => (
                <div key={key}>
                  <h2 className="roadmap__panel-title">{year.year}</h2>
                  <Row>
                    {
                      year.quartals.map((quartal, key) => (
                        <Col key={key} className="col-12 lg-6 roadmap__quartal">
                          <h3 className="roadmap__quartal--name"><span className="roadmap__quartal--circle"></span><span>{quartal.name}</span> {year.year}</h3>
                          <ul className="roadmap__quartal--list">
                            {
                              quartal.list.map((item, x) => (
                                <li key={x}>
                                  <span className="roadmap__quartal--item">
                                    <span className="roadmap__quartal--itemName">
                                      {item.name}
                                    </span>
                                    {
                                      item.type &&
                                      <span className={`roadmap__quartal--itemType ${item.type === 'Project' ? '-project' : ''} ${item.type === 'Product' ? '-product' : ''} ${item.type === 'Marketing' ? '-marketing' : ''}`}>{item.type}</span>
                                    }
                                  </span>
                                  {
                                    item.innerList &&
                                    <ul className="roadmap__quartal--innerList">
                                      {
                                        item.innerList.map((item, key) => (
                                          <li>{item}</li>
                                        ))
                                      }
                                    </ul>
                                  }
                                </li>

                              ))
                            }
                          </ul>
                        </Col>
                      ))
                    }
                  </Row>
                </div>
              ))
            }
          </div>
        </Box>
        <SubscribeWrapper>
          <Box className="subscribe-box-wrapper">
            <Container>
              <Row>
                <Col className="col-12">
                  <Box className="subscribe-box-bg">
                    <Row>
                      <Col className="lg-6 offset-lg-3 xs-12">
                        <SectionTitle>
                          <Heading>Become a beta tester and get whitelisted for airdrop</Heading>
                        </SectionTitle>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="lg-8 offset-lg-2 xs-12">
                        <Box className="form-box">
                          {!subscribed ?
                            <>
                              <Input
                                style={{ 'height': '80px', 'paddingLeft': '28px', 'borderRadius': '10px' }}
                                type="email"
                                placeholder="Enter your email address . . ."
                                handleEmailChange={(e) => handleEmailChange(e)}
                              />
                              <Button onClick={() => handleSubscribe()}>Subscribe</Button>
                            </>
                            :
                            <div style={{'textAlign':'center'}}>
                              <span className="successfuly-subscribed">You have successfuly subscribed to become a beta tester!</span>
                            </div>}
                          {emailError && <div className="subscribe-error"><span>Enter email!</span></div>}
                          {showError && <div className="subscribe-error"><span>There was an error, please try again!</span></div>}
                          {validEmailError && <div className="subscribe-error"><span>Enter a valid email!</span></div>}
                        </Box>
                      </Col>
                    </Row>
                  </Box>
                </Col>
              </Row>
            </Container>
          </Box>
        </SubscribeWrapper>
      </Container> */}

    </TokenWrapper>
  )
}

export default Token

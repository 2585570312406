import React from "react";
import { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo";
import Navigation from "sections/Navigation";
// import BannerThree from "sections/BannerThree"
import Service from "sections/Service";
import Footer from "sections/Footer";
import Stack from "sections/Stack";

import theme from "assets/theme/theme";
import GlobalStyle from "assets/theme";
import Roadmap from "../sections/Roadmap";
import SupportedBySlider from "../sections/SupportedBySlider";
import Team from "../sections/Team";
import Token from "../sections/Token";
import MainFeatures from "../sections/Main-features";
import BannerSlider from "../sections/BannerSlider";

const IndexPage = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Layout>
      <SEO />
      <Navigation />
      {/* <BannerThree /> */}
      <BannerSlider />
      <Service />
      <MainFeatures />
      <Roadmap />
      <Token />
      <Team />
      <SupportedBySlider />
      <Stack />
      <Footer />
    </Layout>
  </ThemeProvider>
)

export default IndexPage

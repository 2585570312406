import React from "react"
import { Container, Row, Col } from "reusecore/Layout"
import Heading from "reusecore/Heading"
import Box from "reusecore/Box"
import Text from "reusecore/Text"
import { SectionTitle, SectionBackground } from "reusecore/SectionTitle"
// import simonImg from "../../assets/images/team/simon-hohler.jpeg";
// import ervinImg from "../../assets/images/team/ervin-marguc.jpeg";
// import alenImg from "../../assets/images/team/alen-lipus.jpeg";
// import peterImg from "../../assets/images/team/peter-znuderl.jpeg";
// import vidImg from "../../assets/images/team/vid-hribljan.jpg";
// import anjaImg from "../../assets/images/team/anja-jezernik.jpeg";

import Image from "reusecore/Image"
import teamWork from '../../assets/images/team/teamwork.png';

import TeamWrapper from "./team.style"

// const teamData = [
//   {
//     image: simonImg,
//     name: 'Simon Hohler',
//     position: 'Co-founder, CEO',
//   },
//   {
//     image: ervinImg,
//     name: 'Ervin Marguč ',
//     position: 'Co-founder, CTO',
//   },
//   {
//     image: alenImg,
//     name: 'Alen Lipuš',
//     position: 'CSO',
//   },
//   {
//     image: peterImg,
//     name: 'Peter Žnuderl',
//     position: 'PMO',
//   },
//   {
//     image: vidImg,
//     name: 'Vid Hribljan ',
//     position: 'Lead Developer',
//   },
//   {
//     image: anjaImg,
//     name: 'Anja Jezernik',
//     position: 'Marketing',
//   },
// ]

const Team = () => {


  return (
    <TeamWrapper id="team">
      <Container>
        {/* <SectionTitle>
          <Heading>
            TEAM
          </Heading>
          {/* <Text></Text> */}
        {/*</SectionTitle> */}
        {/* <div className="team">
          <Row>
            {
              teamData.map((member, key) => (
                <Col className="col-12 lg-4 sm-6" key={key}>
                  <div className="team__member">
                    <div className="team__image">
                      <img src={member.image} alt={member.name} />
                    </div>
                    <span className="team__name">{member.name}</span>
                    <span className="team__position">{member.position}</span>
                  </div>
                </Col>
              ))
            }
          </Row>
        </div> */}

        <Row>

          
          <Col className="lg-5 md-6 sm-12">
            <Box className="about-right-content">
              <SectionTitle>
                <SectionBackground>
                  <Heading as="h1">
                    Team
                  </Heading>
                </SectionBackground>
                <Text>
                  We are an interdisciplinary team of passionate developers, researchers and visionaries from all around the world - Switzerland, Slovenia, United States and Germany.
                </Text>
              </SectionTitle>
            </Box>
          </Col>

          <Col className="lg-6 offset-lg-1 md-6 sm-12">
            <Image
              src={teamWork}
              className="team-image"
              alt="cryptik about image"
            />
          </Col>

        </Row>



      </Container>
    </TeamWrapper>
  )
}

export default Team

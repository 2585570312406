import styled from "styled-components";


const TokenWrapper = styled.div`
padding: 40px 0;

.about-right-content {
  padding-top: 60px;
}

@media only screen and (min-width: 768px) {
padding: 80px 0;
  
} 

.about-list {
  margin-top: 24px;
  span {
    font-size: 18px;
    display: block;
    position: relative;
    padding-left: 43px;
    color: #fff;
    margin-bottom: 20px;

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      height: 30px;
      width: 30px;
      background: transparent;
      border: 8px solid #FFC500;
      border-radius: 50%;
      content: " ";
    }

    &:nth-child(2) {
      &::before {
        border-color: #008DCE;
      }
    }
    &:nth-child(3) {
      &::before {
        border-color: #0D4786;
      }
    }
  }
}

.form-box {
  position: relative;
  margin-top: 70px;
  button {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #fff;
    width: 220px;
    border-radius: 10px;
    border: none;
    transition: all 0.3s ease-in;
    background: #008dce;
    &:hover{
      background: #ffc500;
    }
  }

  .subscribe-error {
    margin-top: 8px;
    color: #F3737A;
    font-size: 16px;
  }

  .successfuly-subscribed {
    font-size: 24px;
    text-align: center;
    color: #FFC500;
  }
}

.roadmap {
  &__container {
    position: relative;
    
    @media only screen and (min-width: 992px) {

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 1px;
      height: 100%;
      background-color: #ffc500;
      z-index: -1;
    }

  }
  }

  &__panel-title {
    display: none;
    text-align: center;
    padding: 40px 0 ;
    background-color: #003166;
    font-size: 60px;
    font-weight: 400;

    @media only screen and (min-width: 992px) {
      display:block
    }
    
  }

  &__quartal {


    @media only screen and (min-width: 992px) {
   

      &:nth-child(odd) {
          text-align: right;


        .roadmap__quartal--circle {
          right: -15px;
        transform: translate(50%, -50%);
        }

        .roadmap__quartal--itemName {
          order: 2;
        }
        .roadmap__quartal--itemType {
          order: 1;
        }

        .roadmap__quartal--list li::before {
          right: 0;
        }
      }

      &:nth-child(even) {
        margin-top: 150px;

        .roadmap__quartal--circle {
          left: -15px;
        transform: translate(-50%, -50%);
        }

        .roadmap__quartal--list li::before {
          left: 0;
        }
      }
    }

    &--circle {
    @media only screen and (min-width: 992px) {

      position: absolute;
      top:50%;
      width: 70px;
      height: 70px;
      background-color: #003166;

      &::before, &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      } 

      &::before {
        width: 40px;
        height: 40px;
        border: 8px solid rgba(255, 197, 0, .2);
      }

      &::after {
        width: 10px;
        height: 10px;
        border: 6px solid rgba(255, 197, 0, 1);
      }
    }
    }

    &--name {
      position: relative;
      font-size: 32px;
      font-weight: 400;

      @media only screen and (min-width: 768px) {
        font-size: 36px;
      }
      
      span {
        font-weight: bold;
      }

      @media only screen and (min-width: 992px) {
          padding: 0 45px;
          font-size: 40px;
      }
    }

    &--item {
      display: inline-flex;
      align-items: flex-start;
    }

    &--list {
      font-size: 16px;
      padding: 0;
      color: rgba(255,255,255, .9);

      @media only screen and (min-width: 768px) {
        font-size: 18px;
      }
      
      @media only screen and (min-width: 992px) {
        padding: 0 45px;
       }

       li {
         position: relative;
         padding: 8px 20px;
         margin: 0 5px;
         text-indent: 0;
         list-style-type: none;

         &::before {
           content: '';
            position: absolute;
           width: 5px;
           height: 5px;
           top: 19px;
           background-color: #ffc500;
           border-radius: 50%;
              @media only screen and (max-width: 992px) {
                left: 0;
              }
          }

       }
    }
  }
}


  .btn-wrapper{
    margin-top: 40px;
    @media only screen and (min-width: 768px) {
      margin-top: 60px;
    
    }

  }
  .btn {
    margin:0 auto;

  }

  .btn-fill {
    color: #003166;
    background: #FFC500;
    display: flex;
    align-items: center;
    height: max-content;
    max-width: max-content;
    font-size: 16px;
    
    @media only screen and (min-width: 768px) {
    font-size: 20px;
      
    }
  }

  
`;

export default TokenWrapper;

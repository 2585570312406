import styled from "styled-components";


const MainFeaturesCoinFundWrapper = styled.div`
padding: 40px 0;

.offer-wrap {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
}

@media only screen and (min-width: 768px) {
  padding: 80px 0 120px;
  .offer-wrap {
    width: 75%;
  }
} 

.heading {
  font-size: 38px;
  text-align:center;
}  

.mainFeatures__img {
display: flex;
justify-content: center;
margin-top: 80px;

img {
  width: 100%;
  max-width: 600px;
}
}
`;

export default MainFeaturesCoinFundWrapper;

import styled from "styled-components";

const teamWrapper = styled.div`
padding: 40px 0;

@media only screen and (min-width: 768px) {
padding: 80px 0;
  
} 

  .team-image {
    opacity: 0.9;
  }

  .heading {
    // text-align:center;
  }

  .team {
    
    &__member {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align:center;
      margin-top: 80px;
    }
    &__image {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      border: 6px solid #FFC500;
      border-top: 0px;
      border-bottom: 0;
      overflow: hidden;
      margin: 0 auto;

      img {
        width: 200px;
        height: 200px;
        object-fit: cover;

      }
    }

    &__name {
      font-size: 24px;
      color: #fff;
      margin-top: 30px;
    }

    &__position {
      margin-top: 5px;
    }
  }
`;

export default teamWrapper;

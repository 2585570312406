import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import { SectionTitle, SectionBackground } from "reusecore/SectionTitle"
import subscribe from "../../common/index.js";
import Input from "../../reusecore/Form/Input/index.js";
import Button from "../../reusecore/Button/index.js";
import SubscribeWrapper from "../Subscribe/subscribe.style.js";
import { Link as OnepageLink } from "react-scroll"

import RoadmapWrapper from "./roadmap.style"

const Roadmap = () => {

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [validEmailError, setValidEmailError] = useState(false);
  const [subscribed, setSubscribed] = useState(false);

  const handleEmailChange = (event) => {
    setEmailError(false);
    setShowError(false);
    setValidEmailError(false);
    const value = event.target.value;
    setEmail(value);
  }

  const handleSubscribe = () => {
    subscribe({
      email: email,
      subscribed_to_updates: false,
      applied_as_beta_user: true,
      list_id: "c558a7dc-396c-4419-b39e-56a608b63e45"
    })
      .then((response) => {
        setSubscribed(true);
      }).catch(error => {
        const jsonError = JSON.parse(error.request.response)
        if (jsonError.error_first_message === 'To polje ne sme biti prazno.') {
          setEmailError(true);
        }
        else if (jsonError.error_first_message === 'Vnesite veljaven elektronski naslov.') {
          setValidEmailError(true);
        }
        else {
          setShowError(true);
        }
      });
  }

  return (
    <>
      <RoadmapWrapper id="roadmap">
        <Container>
          <Row>
            <Col className="col-12 md-6">
              <Box className="coin-fund-content-left">
                <SectionTitle>
                  <SectionBackground>
                    <Heading>
                      Roadmap
                    </Heading>
                  </SectionBackground>
                  <Text>
                    The project was started back in 2019 when it won an EU public tender and we got the opportunity bring our idea to life. Today the platform BETA IS LIVE ON testnet and we are planning to launch during the upcoming months.
                    <br /><br /> We invite anyone to become a beta tester and we will whitelist your address for the Airdrop reward.
                  </Text>
                </SectionTitle>

                <Box className="btn-wrapper">
                  <Link to="/roadmap" className="btn btn-fill" style={{marginRight: '20px'}}>
                    See Roadmap
                  </Link>
                  <OnepageLink
                        className="btn btn-fill"
                        to="apply-for-beta"
                        spy={true}
                        smooth={true}
                        offset={-160}
                        duration={700}
                      >
                        Become Beta Tester
                      </OnepageLink>
                </Box>
              </Box>
            </Col>
            <Col className="lg-6 md-12 countdown-wrap">
              <></>
            </Col>
          </Row>
        </Container>
      </RoadmapWrapper>
      <SubscribeWrapper>
        <Box className="subscribe-box-wrapper" id="apply-for-beta">
          <Container>
            <Row>
              <Col className="col-12">
                <Box className="subscribe-box-bg">
                  <Row>
                    <Col className="lg-6 offset-lg-3 xs-12">
                      <SectionTitle>
                        <Heading>Become a beta tester and get whitelisted for airdrop</Heading>
                      </SectionTitle>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="lg-8 offset-lg-2 xs-12">
                      <Box className="form-box">
                        {!subscribed ?
                          <>
                            <Input
                              style={{ 'height': '80px', 'paddingLeft': '28px', 'borderRadius': '10px' }}
                              type="email"
                              placeholder="Enter your email address . . ."
                              handleEmailChange={(e) => handleEmailChange(e)}
                            />
                            <Button onClick={() => handleSubscribe()}>Subscribe</Button>
                          </>
                          :
                          <div style={{ 'textAlign': 'center' }}>
                            <span className="successfuly-subscribed">You have successfuly subscribed to become a beta tester!</span>
                          </div>}
                        {emailError && <div className="subscribe-error"><span>Enter email!</span></div>}
                        {showError && <div className="subscribe-error"><span>There was an error, please try again!</span></div>}
                        {validEmailError && <div className="subscribe-error"><span>Enter a valid email!</span></div>}
                      </Box>
                    </Col>
                  </Row>
                </Box>
              </Col>
            </Row>
          </Container>
        </Box>
      </SubscribeWrapper>
    </>

  )
}

export default Roadmap

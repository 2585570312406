const Data = [
  {
    year: '2022',
    quartals: [
      {
        name: 'Q4',
        list: [
          {
            name: 'Beta Launch',
          },
        ]
      }
    ]
  },
  {
    year: '2023',
    quartals: [
      {
        name: 'Q1',
        list: [
          {
            name: 'AirDrop',
          },
          {
            name: 'Going Live',
          }
        ]
      }
    ]
  },
]
export default Data

import styled from "styled-components";

const SupportedBySliderWrapper = styled.div`
padding: 40px 0;

@media only screen and (min-width: 768px) {
padding: 80px 0;
  
} 
  
  .slick__slider {
    margin-top: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color:#0D4786;
    border-radius: 10px;
@media only screen and (min-width: 768px) {
  padding-top: 40px;
  padding-bottom: 40px;
}
  }

.slick-track {
  display: flex;
  align-items: center;
}


  .slick__slider__item {
    text-align: center;

    img {
      margin: 0 auto;
      max-width: 120px;
      max-height: 76px;


@media only screen and (min-width: 768px) {
  max-width: 198px;
  max-height: 76px;
}
    }
  }

`;

export default SupportedBySliderWrapper;
